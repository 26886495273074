<template>
  <div class="container">
    <form-panel
      v-bind="submitConfig"
      ref="formPanel"
      :form="form"
      :footerShow="true"
      :submitBefore="submitBefore"
    >
      <col2-detail>
        <col2-block>
          <el-form-item
            label="所属公司"
            :rules="[
              { required: true, message: '所属公司不能为空', trigger: 'blur' },
            ]"
            prop="companyName"
          >
            <span>{{ form.companyName }}</span>
          </el-form-item>

          <el-form-item
            label="所属项目"
            :rules="[
              { required: true, message: '所属项目不能为空', trigger: 'blur' },
            ]"
            prop="communityId"
          >
            <v-select2
              v-model="form.communityId"
              v-bind="communityParams"
              :width="width"
              @onChange="changeCommunityId"
            ></v-select2>
          </el-form-item>

          <el-form-item
            label="服务大类"
            :rules="[
              { required: true, message: '请选择服务大类', trigger: 'change' },
            ]"
            prop="volunteerCat"
          >
            <v-select
              v-model="form.volunteerCat"
              :options="typeIdOps"
              :width="width"
              :filterable="true"
              :clearable="true"
              :disabled="!form.communityId"
              @change="changeVolunteerCat"
            />
          </el-form-item>

          <el-form-item
            label="参与岗位"
            :rules="[
              { required: true, message: '请选择参与岗位', trigger: 'change' },
            ]"
            prop="volunteerDuty"
          >
            <v-select
              v-model="form.volunteerDuty"
              :options="dtyListOpss"
              :width="width"
              :filterable="true"
              :clearable="true"
              :disabled="!form.volunteerCat"
              @change="changeVolunteerDuty"
            />
          </el-form-item>

          <el-form-item
            label="用户姓名"
            prop="userId"
            :rules="[
              {
                required: true,
                message: '请选择用户姓名',
                trigger: 'change',
              },
            ]"
          >
            <v-select2
              v-model="form.userId"
              v-bind="houseParams"
              :width="width"
              @onChange="changeUserName"
            ></v-select2>
          </el-form-item>

          <el-form-item label="照片" prop="volunteerPic">
            <v-uploader :action="uploadURL" :fileList.sync="form.volunteerPic">
            </v-uploader>
          </el-form-item>

          <el-form-item label="个人擅长" prop="volunteerKey">
            <v-textarea
              v-model="form.volunteerKey"
              placeholder="请输入..."
              :maxlength="100"
              :width="500"
            ></v-textarea>
          </el-form-item>

          <el-form-item
            label="志愿者描述"
            prop="introduce"
          >
            <v-textarea
              v-model="form.introduce"
              placeholder="请输入..."
              :maxlength="200"
              :width="500"
            ></v-textarea>
          </el-form-item>
        </col2-block>
      </col2-detail>
    </form-panel>
  </div>
</template>
<script>
import { Col2Detail, Col2Block } from "components/bussiness";
import { uploadURL, getTypeIdURL, dutyListUrl, getOwnerList, getProjectTreeUrl, volunteerAddUrl } from "./api";
import { vUploader } from "components/control";
import { communityParams, regionParams } from "@/common/select2Params";

export default {
  name: "volunteerAddNewForm",
  components: {
    vUploader,
    Col2Detail,
    Col2Block,
  },
  data() {
    return {
      communityParams,
      width: 182,
      uploadURL: uploadURL,
      regionId: '',
      form: {
        companyName: '',
        communityId: undefined, // 园区id
        introduce: "", // 自我介绍
        userId: undefined, // 用户id
        volunteerCat: undefined, // 大类
        volunteerDuty: undefined, // 岗位
        volunteerKey: "", // 擅长
        volunteerPic: [],
      },
      submitConfig: {
        submitUrl: volunteerAddUrl,
        submitContentType: "application/json",
        submitMethod: 'PUT'
      },
      dtyListOpss: [],
      typeIdOps: [],
      roleId: null,
      houseParams: {
        searchUrl: getOwnerList,
        request: {
          text: 'ownerName',
          value: 'id',
        },
        response: {
          text: 'name',
          value: 'id',
        },
        method: 'get'
      },
      communityId: undefined,
    };
  },
  created() {
    const { roleId } = this.$route.query
    console.log('==roleId==', roleId)
    this.roleId = roleId
    this.getCompanyName()
  },
  methods: {
    submitBefore(data) {
      console.log(data, "---data");
      for (let k in data) {
        if (!data[k] || data[k].length === 0) {
          delete data[k];
        }
      }
      delete data.companyName;

      if(data.volunteerPic && data.volunteerPic.length > 0) {
        const picUrl = data.volunteerPic[0].url
        data.volunteerPic = picUrl
      }
      console.log(data, "++++data");

      return true
    },
    changeCommunityId(data) {
      if(data) {
        this.communityId = data.id;
        this.getTypeIdData();

        if(this.form.volunteerCat) {
          this.getDutyList(this.form.volunteerCat)
        }
      }
    },
    changeVolunteerCat(data) {
      console.log('++++data', data)
      this.form.volunteerDuty = undefined
      if(data === true || !data) {
        this.form.volunteerCat = undefined
      }
      this.getDutyList(data)
    },
    changeVolunteerDuty(data) {
      if(data === true || !data) {
        this.form.volunteerDuty = undefined
      }
    },
    changeUserName(data) {
      console.log(data, "---data");
    },
    getTypeIdData() {
      const _this = this;
      let params = {
        communityId: this.form.communityId,
      };
      for (let k in params) {
        if (!params[k]) {
          delete params[k];
        }
      }
      _this.typeIdOps = [];
      this.$axios.get(getTypeIdURL, { params }).then((res) => {
        if (res.status == "100") {
          const data = res.data;
          data.forEach((item) => {
            _this.typeIdOps.push({
              text: item.categoryName,
              value: item.id,
            });
          });
        }
      });
    },
    getDutyList(id) {
      const _this = this;
      _this.dtyListOpss = [];
      this.$axios.get(dutyListUrl + `?curPage=1&pageSize=300&communityId=${this.communityId}&volunteerCat=${id}&isExamine=1`).then((res) => {
        if (res.status == "100") {
          const data = res.data.resultList;
          console.log('data---', data)
          if(data.length > 0) {
            data.forEach((item) => {
              _this.dtyListOpss.push({
                text: item.volunteerDuty,
                value: item.jobId,
              });
            });
          } else {
            _this.dtyListOpss = []
          }
        }
      });
    },
    getCompanyName() {
      this.$axios.get(getProjectTreeUrl + `?nodeLevel=0`).then(res => {
        if (res.status == "100") {
          const data = res.data[0]
          this.form.companyName = data.name
        }
      })
    }
  },
};
</script>
<style lang="scss" scoped>
.container {
  width: 100%;
  display: flex;
  flex-direction: column;
}
</style>
