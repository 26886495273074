var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c(
        "form-panel",
        _vm._b(
          {
            ref: "formPanel",
            attrs: {
              form: _vm.form,
              footerShow: true,
              submitBefore: _vm.submitBefore,
            },
          },
          "form-panel",
          _vm.submitConfig,
          false
        ),
        [
          _c(
            "col2-detail",
            [
              _c(
                "col2-block",
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "所属公司",
                        rules: [
                          {
                            required: true,
                            message: "所属公司不能为空",
                            trigger: "blur",
                          },
                        ],
                        prop: "companyName",
                      },
                    },
                    [_c("span", [_vm._v(_vm._s(_vm.form.companyName))])]
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "所属项目",
                        rules: [
                          {
                            required: true,
                            message: "所属项目不能为空",
                            trigger: "blur",
                          },
                        ],
                        prop: "communityId",
                      },
                    },
                    [
                      _c(
                        "v-select2",
                        _vm._b(
                          {
                            attrs: { width: _vm.width },
                            on: { onChange: _vm.changeCommunityId },
                            model: {
                              value: _vm.form.communityId,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "communityId", $$v)
                              },
                              expression: "form.communityId",
                            },
                          },
                          "v-select2",
                          _vm.communityParams,
                          false
                        )
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "服务大类",
                        rules: [
                          {
                            required: true,
                            message: "请选择服务大类",
                            trigger: "change",
                          },
                        ],
                        prop: "volunteerCat",
                      },
                    },
                    [
                      _c("v-select", {
                        attrs: {
                          options: _vm.typeIdOps,
                          width: _vm.width,
                          filterable: true,
                          clearable: true,
                          disabled: !_vm.form.communityId,
                        },
                        on: { change: _vm.changeVolunteerCat },
                        model: {
                          value: _vm.form.volunteerCat,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "volunteerCat", $$v)
                          },
                          expression: "form.volunteerCat",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "参与岗位",
                        rules: [
                          {
                            required: true,
                            message: "请选择参与岗位",
                            trigger: "change",
                          },
                        ],
                        prop: "volunteerDuty",
                      },
                    },
                    [
                      _c("v-select", {
                        attrs: {
                          options: _vm.dtyListOpss,
                          width: _vm.width,
                          filterable: true,
                          clearable: true,
                          disabled: !_vm.form.volunteerCat,
                        },
                        on: { change: _vm.changeVolunteerDuty },
                        model: {
                          value: _vm.form.volunteerDuty,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "volunteerDuty", $$v)
                          },
                          expression: "form.volunteerDuty",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "用户姓名",
                        prop: "userId",
                        rules: [
                          {
                            required: true,
                            message: "请选择用户姓名",
                            trigger: "change",
                          },
                        ],
                      },
                    },
                    [
                      _c(
                        "v-select2",
                        _vm._b(
                          {
                            attrs: { width: _vm.width },
                            on: { onChange: _vm.changeUserName },
                            model: {
                              value: _vm.form.userId,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "userId", $$v)
                              },
                              expression: "form.userId",
                            },
                          },
                          "v-select2",
                          _vm.houseParams,
                          false
                        )
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "照片", prop: "volunteerPic" } },
                    [
                      _c("v-uploader", {
                        attrs: {
                          action: _vm.uploadURL,
                          fileList: _vm.form.volunteerPic,
                        },
                        on: {
                          "update:fileList": function ($event) {
                            return _vm.$set(_vm.form, "volunteerPic", $event)
                          },
                          "update:file-list": function ($event) {
                            return _vm.$set(_vm.form, "volunteerPic", $event)
                          },
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "个人擅长", prop: "volunteerKey" } },
                    [
                      _c("v-textarea", {
                        attrs: {
                          placeholder: "请输入...",
                          maxlength: 100,
                          width: 500,
                        },
                        model: {
                          value: _vm.form.volunteerKey,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "volunteerKey", $$v)
                          },
                          expression: "form.volunteerKey",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "志愿者描述", prop: "introduce" } },
                    [
                      _c("v-textarea", {
                        attrs: {
                          placeholder: "请输入...",
                          maxlength: 200,
                          width: 500,
                        },
                        model: {
                          value: _vm.form.introduce,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "introduce", $$v)
                          },
                          expression: "form.introduce",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }